.marked-location-icon {
  color: red;
}

.location-icon {
  color: green;
}

.location-item {
  font-size: 36px;
}

div.leaflet-control>div>ul>li {
  white-space: nowrap;
}