.marker-label-wrapper {
  width: 165px;
  position: relative;
}

.marker-label {
  position: absolute;
  width: 165px;
  transform: translate(-50%, -50%);
  margin: 7px auto auto 7px;
  padding: 8px;
  border-radius: 4px;
  background-color: white;
  font-family: sans-serif;
  font-size: 12px;
  color: #212121;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
}

.marker-label-title {
  font-weight: 300;
  color: #3f65ab;
}

.marker-icon {
  margin: 10px auto auto 10px;
  transform: translate(-70%, -70%);
}

.black-dot {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 50%;
  margin: 8px auto auto 8px;
  transform: translate(-70%, -70%);
}
