.leaflet-container-custom-cursor {
    cursor: url(cursor.png), pointer;
}

.leaflet-subject-icon {
    content: url(round_star_black_24dp.png);
    margin: 8px auto auto 8px;
    font-size: 12px;
    transform: translate(-55%, -55%);
}
