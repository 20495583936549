.handsontable td {
  border-top-width: 0;
  border-left-width: 0;
  border-right: 1px solid #d7deea;
  border-bottom: 1px solid #d7deea;
  height: 20px;
  font-size: 14px;
  empty-cells: show;
  line-height: 1.43;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  text-align: left;
  padding-left: 16px;
  overflow: hidden;
  white-space: nowrap;
  outline-width: 0;
  font-family: Nunito Sans, sans-serif;
  background-color: #ffffff;
}

.handsontable {
  border: none;
}

.handsontable span.colHeader {
  padding: 10px 8px;
}

.handsontable th {
  color: #222222;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  border-top: solid 1px #d7deea;
  background-color: #f8fafc;
  border-bottom: none;
  font-family: Nunito Sans, sans-serif;
}

.handsontable td.readOnly {
  text-align: right;
  padding-right: 16px;
  color: #617083;
  background-color: #f8fafc;
}

.handsontable thead th {
  text-align: right;
  border-right: 0;
  border-bottom: solid 1px #d7deea;
}

.handsontableInput {
  box-shadow: 0 0 0 2px #4160d3 inset;
}

.readOnly {
  color: #617083;
  background-color: #f8fafc;
  text-align: right;
}

.disabled {
  color: #617083;
  text-align: left;
}

.handsontable .invalid {
  border: 1px solid red !important;
  box-shadow: 0 0 0 1px #FFB3B3;
  position: relative;
}

.handsontable tr {
  background: #ffffff;
}

.handsontable th:first-child {
  border-left: solid 1px #d7deea;
  border-top-left-radius: 4px;
}

.handsontable th:last-child {
  border-top-right-radius: 4px;
  border-right: solid 1px #d7deea;
}

.handsontable tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.handsontable tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.handsontable tr td:last-child {
  border-right: solid 1px #d7deea;
}

.handsontable tr td:first-child {
  border-left: solid 1px #d7deea;
}

.handsontable input.htCheckboxRendererInput {
  margin: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border: solid 1px #222222;
  border-radius: 2px;
}

.handsontable thead th.ht__highlight {
  background-color: #f8fafc;
}

.handsontable tbody th.ht__active_highlight,
.handsontable thead th.ht__active_highlight {
  background-color: #b3c3ff;
  color: #222222;
}

.alignRight {
  text-align: right !important;
}

.error {
  border: 1px solid #d24141 !important;
}

.alignLeft {
  text-align: left !important;
}

.alignCenter {
  text-align: center !important;
}

td.totalRow {
  text-align: right;
  font-weight: bold;
  border-right: 0;
  background-color: #f8fafc;
  color: #617083;
}

.totalRow {
  text-align: right;
  font-weight: bold;
  border-right: 0;
  background-color: #f8fafc;
  color: #617083;
}

td.totalRowLeft {
  text-align: left !important;
  font-weight: bold;
  border-right: 0;
  overflow: visible !important;
  background-color: #f8fafc;
  color: #617083;
}

.handsontable .htRowHeaders div {
  text-align: left;
  padding: 16px;
  height: 36px !important;
}

th.rowHeader {
  text-align: left;
  vertical-align: middle;
  border-radius: 0 !important;
  margin: 0;
  height: 36px !important;
}

.rowHeader span {
  /* padding-top: 8px; */
  height: 36px !important;
}
